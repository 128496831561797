import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className="bg-darkGray text-sm">
      <div className="flex justify-center">
        <div className="flex flex-1 max-w-6xl justify-between p-6 pt-14 lg:px-8 text-white text-lato flex-wrap">
          <Link to="/" className="-m-1.5 p-1.5 mr-6">
            <span className="sr-only">Revyse</span>
            <img
              className="h-8 w-auto"
              src="/assets/revyse-logo-color-white.png"
              alt=""
              width="336"
              height="116"
            />
          </Link>
          <div className="flex flex-grow justify-between md:justify-around mt-5 md:mt-0 mb-5 basis-full md:basis-0">
            <div className="flex flex-col">
              <Link className="mb-2" to="/who-we-are">
                Who We Are
              </Link>
              {/* <Link to="/careers">Careers</Link> */}
              <div
                onClick={() => {
                  window.open("https://community.revyse.com", "_blank");
                }}
                className="mb-2"
              >
                Community
              </div>
            </div>
            <div className="flex flex-col">
              <Link className="mb-2" to="mailto:hello@revyse.com">
                Contact
              </Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="flex flex-col">
              <Link className="mb-2" to="/terms-of-use">
                Terms of Use
              </Link>
              <Link to="/vendor-terms">Vendor Terms</Link>
            </div>
          </div>

          <form
            method="post"
            className="flex flex-col"
            action="https://revyse.us14.list-manage.com/subscribe/post?u=d34fa96ceb13bef2c75ffa476&amp;id=b97f1bab18&amp;f_id=00169ae0f0"
            target="_blank"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
          >
            <p className="mb-2">Subscribe to our newsletter for updates</p>
            <input
              className="mb-2 rounded text-black"
              type="email"
              placeholder="Email"
              name="EMAIL"
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_d34fa96ceb13bef2c75ffa476_b97f1bab18"
                tabIndex={-1}
                defaultValue=""
              />
            </div>
            {/* //     <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div> */}
            <input
              className="rounded-3xl bg-sky-600 w-24 py-2 px-3"
              type="submit"
              defaultValue="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </form>

          <div className="mt-10 text-white/50 basis-full text-center">
            Copyright 2024 &copy; Revyse. All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
}
